export const STATS = {
    // Home page
    all: {
        anchors: 141,
        borrowers: 17485,
        invoices: 343224,
        disbursed: 29118702595.12,
        aum: 3065899289.0,
    },
    // SaralPay Later
    bnpl: {
        anchors: 24,
        borrowers: 2889,
        invoices: 19136,
        disbursed: 9518077309.78,
        aum: 395897183.0,
    },
    // Vendor Finance
    vendor: {
        anchors: 9,
        borrowers: 82,
        invoices: 910,
        disbursed: 7102490462.34,
        aum: 933640218.0,
    },
    // Supplychain finance
    scf: {
        anchors: 21,
        borrowers: 21,
        invoices: 1078,
        disbursed: 12498134823.0,
        aum: 1736361888.0,
    },
};
